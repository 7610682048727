import { AnchorLink } from "gatsby-plugin-anchor-links"
import * as React from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import "./hero.css"

const Hero = ({ translation, text, image }) => {

    const { t } = useTranslation();
    return (
        <section className="hero">
            <img src={image} alt="" />
            <AnchorLink to="/#contact" className="hero-side hero-side--contact">
                <span className="hero-side--text">{translation.contactForm}</span>
                <span className="hero-side--icon">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.58 11.36C15.7 10.24 16.4 8.84 16.4 7.3C16.4 3.8 12.97 1 8.7 1C4.43 1 1 3.8 1 7.3C1 10.8 4.43 13.6 8.7 13.6C9.47 13.6 10.17 13.53 10.87 13.32L15 15L14.58 11.36Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.90039 5.89999H11.5004" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.90039 8.7H9.40039" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </AnchorLink>
            <AnchorLink to="/#bestsellers" className="hero-popup">
                <span className="hero-popup__text">
                    {translation.bestsellers}
                </span>
            </AnchorLink>
            <div className="hero__main">
                <div className="container">
                    <div className="hero__text">
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                        <AnchorLink to="/#about" className="button">{translation.findOutMore}</AnchorLink>
                        {/* <!--FIND OUT MORE--> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Hero