import * as React from "react"
import { useInView } from 'react-intersection-observer';

import './features.css'

const Features = ({ translation, title, image, type, features }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
      });
    const sectionClass = `why-axtel why-axtel--${type}`;
    return (<section className={sectionClass}>
        <div className="container"  ref={ref} style={{
        transition: "opacity 300ms, transform 300ms",
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 100}px)`,
    }}>
            <h2>{title}</h2>
            <div className="why-axtel__image">
                {features.map((value) => {
                    const pinName = `why-axtel__pin why-axtel__pin--${value.To_right ? 'right' : 'left'}`;
                    
                    let style = {
                        top: `${value.Top}%`
                    }

                    if(value.To_right) {
                        style.right = `${value.Left}%`
                    } else {
                        style.left = `${value.Left}%`
                    }
                    return(<div className={pinName} style={style}>
                        <div dangerouslySetInnerHTML={{ __html: value.Text }}></div>
                        <span className="why-axtel__pin__line"></span>
                        <span className="why-axtel__pin__dot"></span>
                    </div>)
                })}
                <img src={image?.localFile?.publicURL} />
            </div>
        </div>
    </section>)
}

export default Features