import * as React from "react"
import { Link } from 'gatsby'

import './contact.css'

const Contact = ({ translation, text }) => (
   
    <section className="contact-us" id="contact">
        <div className="container">
            <div className="contact-us__left"  dangerouslySetInnerHTML={{__html: text}}>
            </div>
            <div className="contact-us__right">
                <h3>{translation.messageTitle}</h3>

                <form action="https://axtelworld.com/send-email.php" method="post" className="form" netlify name="contact">
                    <div className="form__row">
                        <div className="form__row__item"><input required name="name" type="text" placeholder={translation.name} /></div>
                        <div className="form__row__item"><input name="company" type="text" placeholder={translation.company} /></div>
                        <div className="form__row__item"><input required name="country" type="text" placeholder={translation.country} /></div>
                    </div>
                    <div className="form__row">
                        <div className="form__row__item"><input required type="email" name="email" placeholder={translation.email} /></div>
                        <div className="form__row__item"><input type="text" name="phone" placeholder={translation.phone} /></div>
                    </div>
                    <div className="form__row">
                        <div className="form__row__item">
                            <textarea name="" id="" required cols="30" rows="10" name="text" placeholder={translation.message}></textarea>
                        </div>
                    </div>

                    <div className="form__footer">
                        <div className="form__checkbox">
                            <input type="checkbox" required />
                            <label for=""><Link to="/privacy-policy">{translation.privacypolicy}</Link></label>
                        </div>
                        <div className="form__button">
                            <button className="button">{translation.send}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
)

export default Contact