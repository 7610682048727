import * as React from "react"
import Img from 'gatsby-image'
import './try.css'

const Try = ({ text, image }) => (
    <section className="try" id="tests">
        <div className="container">
            <div className="try__text" dangerouslySetInnerHTML={{__html: text}}>
            </div>

            <div className="try__image">
                <Img fluid={image} />
            </div>
        </div>
    </section>
)

export default Try