import * as React from "react"
import { Translation } from "react-i18next"
import './product.css'

const Product = ({ translation, data }) => (
    <div className="product">
        <div className="product__inner">
            <div className="product__image">
                <img alt="" src={data.node.Image?.localFile.publicURL} />
            </div>
            <div className="product__name">{data.node.Nazwa}</div>
            <div className="product__button">
                <a target="_blank" rel="noreferrer" href={data.node.PDF?.localFile.publicURL}>{translation.dataSheet}</a>
            </div>
        </div>
    </div>
)

export default Product