import * as React from "react"
import { useInView } from 'react-intersection-observer';

import './personalize.css'

const Personalize = ({ translation, text, image }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });
    return (
        <section className="personalisation" id="personalize">
            <div className="container">
                <div className="personalisation__image">
                    <div className="personalisation__image__main">
                        <img src={image} alt="" />
                    </div>
                </div>

                <div className="personalisation__text" ref={ref} style={{
                transition: "opacity 300ms, transform 300ms",
                opacity: inView ? 1 : 0,
                transform: `translateY(${inView ? 0 : 100}px)`,
            }}>
                    <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    <a rel="noreferrer" href="https://axtelworld.com/headsets/personalize/" target="_blank" className="button">{translation.visualisation}</a>
                </div>
            </div>
        </section>
    )
}

export default Personalize