import * as React from "react"
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import './about.css'
import DecoratorImg from '../images/headsets-decorator.png'
import { useInView } from 'react-intersection-observer';
SwiperCore.use([Pagination]);

const About = ({ translation, text, images, type, movie, showModal = false }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });
    function handleClick() {
        document.getElementById('vid').classList.add('open');
        document.getElementById('video').play();
    }
    function hideVideo() {
        document.getElementById('vid').classList.remove('open');
        document.getElementById('video').pause();
    }
    
    const sectionClass = `about-headsets about-headsets--${type}`;
    return (<section className={sectionClass} id={type}
    >
        <img alt="" src={DecoratorImg} />
        {movie && (<div href="#" className="about-headsets__play" onClick={handleClick}>
            <span className="about-headsets__play-icon">
                <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.53 5.15258L1.53 0.152578C1.221 -0.0414217 0.833 -0.0504216 0.515 0.125578C0.197 0.302578 0 0.636578 0 1.00058V11.0006C0 11.3646 0.197 11.6986 0.515 11.8756C0.667 11.9586 0.833 12.0006 1 12.0006C1.184 12.0006 1.368 11.9496 1.53 11.8486L9.53 6.84858C9.822 6.66558 10 6.34558 10 6.00058C10 5.65558 9.822 5.33558 9.53 5.15258Z"
                        fill="white" />
                </svg>
            </span>
            <span className="about-headsets__play-text">{translation.viewMovie}</span>
        </div>)}
        <div className="container">
            <div className="about-headsets__left" ref={ref} style={{
                transition: "opacity 300ms, transform 300ms",
                opacity: inView ? 1 : 0,
                transform: `translateY(${inView ? 0 : 100}px)`,
            }} dangerouslySetInnerHTML={{ __html: text }}>
            </div>
            <div className="about-headsets__right">
                <div className="slider">
                    <Swiper slidesPerView={1} pagination={{ clickable: true }}>
                        {images.map((value) => (
                            <SwiperSlide>
                                <img alt="" src={value.localFile.publicURL} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
        <div id="vid" className={`videoPopup`}>
            <a onClick={hideVideo}><svg version="1.1" id="Capa_1"  x="0px" y="0px" viewBox="0 0 512 512">
                <polygon points="512,28.308 483.692,0 256,227.692 28.308,0 0,28.308 227.692,256 0,483.692 28.308,512 256,284.308 483.692,512
			512,483.692 284.308,256 		"></polygon>
            </svg></a>
            <video id="video" src="https://axtelworld.com/wp-content/themes/axtel/assets/video/video.mp4"></video>
        </div>
    </section>)
}

export default About