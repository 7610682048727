import * as React from "react"
import './welcome.css'
import { useInView } from 'react-intersection-observer';


const Welcome = ({ textLeft, textRight }) => {

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });
    return (

        <section ref={ref} className="welcome" id="about" style={{
            transition: "opacity 300ms, transform 300ms",
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 100}px)`,
        }}>
            <div className="container">
                <div className="welcome__left" dangerouslySetInnerHTML={{ __html: textLeft }}>
                </div>
                <div className="welcome__right" dangerouslySetInnerHTML={{ __html: textRight }}>
                </div>
            </div>
        </section>
    )
    }

export default Welcome