import * as React from "react"
import './products.css'
import Product from "./product"
import { useInView } from 'react-intersection-observer';

const Products = ({ translation, products, type }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
      });

    const sectionClass = `bestsellers bestsellers--${type}`;
    return (<section id="bestsellers" className={sectionClass}>
        <div className="container" ref={ref} style={{
        transition: "opacity 300ms, transform 300ms",
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 100}px)`,
    }}>
            <h2>{translation.bestsellers}</h2>
            <div className="bestsellers__items">
                {products.map((value) => {
                    return (<Product translation={translation} data={value} />)
                })}
            </div>
        </div>
    </section>)
}

export default Products